<template>
  <div>
    <!-- SideBar ------------------------------------------------------------------->
    <div :class="['sidebar', { collapsed: isToggled }]">
      <div class="sidebar-header text-center overflow-hidden" style="height: 60px">
        <h5 class="fs-6 overflow-hidden" :class="['title', { collapsed: !isToggled }]">
          {{ !isToggled ? "RENTAL MANAGEMENT SYSTEM" : "RMS" }}
        </h5>
      </div>
      <ul class="sidebar-menu">
        <li class="nav-item" v-for="menu in menus" :key="menu.menu_id">
          <button class="dropdown-btn" @click="toggleDropdown(menu.menu_id)">
            <div class="d-flex align-items-end gap-3 w-100 h-100">
              <div><i class="fa-regular fs-6 fa-circle"></i></div>
              <div style="height: 23px;">
                <span style="font-size: 0.9rem;" :class="['menu-name', { collapsed: !isToggled }]">{{
                  menu.menu_name
                }}</span>
              </div>
            </div>
            <i :class="[
              'fa fa-chevron-down dropdown-icon',
              { rotate: !isOpen[menu.menu_id] },
            ]"></i>
          </button>
          <div :class="['dropdown-container', { show: !isToggled ? !isOpen[menu.menu_id] : true }]">
            <a v-for="(submenuName, index) in getSubmenuNames(menu)" :key="menu.submenu_ids[index]"
              class="menu-item w-100" :href="getSubmenuUrls(menu)[index]"
              @click="setActiveSubmenu(menu.submenu_ids[index])">
              <div class="d-flex align-items-center gap-3 w-100" @click="smallMenu">
                <div><i style="font-size: 0.8rem;" class="fa-solid fa-circle"></i></div>
                <div style="height: 23px;">
                  <span style="font-size: 0.9rem;" :class="['menu-name', { collapsed: !isToggled }]">{{
                    submenuName
                  }}</span>
                </div>
              </div>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- <div :style="{ marginLeft: isCollapsed ? '80px' : '250px' }" class="content">
      <slot></slot>
    </div> -->
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import api from "@/services/apiService.js";

export default {
  props: {
    isToggled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isDarkMode: document.body.classList.contains("dark-mode"),
      user: null,
      Datauser: null,
      DataBroker: null,
    };
  },


  setup() {
    const menus = ref([]);
    const isCollapsed = ref(false);
    const isOpen = ref({});
    const activeSubmenuId = ref(null);

    // Fetch menus from the API
    const fetchMenus = async () => {
      try {
        const response = await api.get("/permission");
        menus.value = response.data.reverse(); // Assuming Axios response structure
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    // Get submenu names from a menu
    const getSubmenuNames = (menu) => {
      return menu.submenu_names.split(",").map((name) => name.trim());
    };

    // Get submenu URLs from a menu
    const getSubmenuUrls = (menu) => {
      return menu.submenu_urls.split(",").map((url) => url.trim());
    };

    // Handle dropdown toggle state
    let checkId = 0;
    const toggleDropdown = (menuId) => {
      const checked = !isOpen.value[menuId];
      if (checked) {
        isOpen.value[checkId] = false;
        isOpen.value[menuId] = true;
        checkId = menuId;
      } else {
        isOpen.value[menuId] = false;
      }
    };

    // Toggle sidebar collapsed state
    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value;
    };

    // Set active submenu ID
    const setActiveSubmenu = (submenuId) => {
      activeSubmenuId.value = submenuId;

    };

    // Check if a submenu is active
    const isActiveSubmenu = (submenuId) => {
      return activeSubmenuId.value === submenuId;
    };

    // Set active submenu based on the current URL
    const setActiveFromUrl = () => {
      const currentUrl = window.location.href;
      for (const menu of menus.value) {
        const urls = getSubmenuUrls(menu);
        const ids = menu.submenu_ids;

        for (let i = 0; i < urls.length; i++) {
          if (currentUrl.includes(urls[i])) {
            activeSubmenuId.value = ids[i];
            return;
          }
        }
      }
    };

    // Fetch menus and set active submenu on component mount
    onMounted(async () => {
      await fetchMenus();
      setActiveFromUrl();
    });

    return {
      menus,
      isCollapsed,
      isOpen,
      activeSubmenuId,
      fetchMenus,
      toggleDropdown,
      toggleSidebar,
      setActiveSubmenu,
      isActiveSubmenu,
      getSubmenuNames,
      getSubmenuUrls,
    };
  },
  methods: {
    smallMenu() {
      if (window.screen.width <= 768) {
        this.$emit("handleSmall");
      }
    }
  }
};
</script>

<style scoped>
/* Style for active submenu item */
.active-submenu {
  background-color: #007bff;
  /* You can choose any color for the active submenu */
  color: #ffffff;
  /* Text color when active */
  font-weight: bold;
  /* Optional: make the active submenu bold */
}

/* Optional: Add hover effect for submenu items */
.menu-item:hover {
  background-color: #0056b3;
  color: #fff;
}

/* Optional: Style for active submenu text */
.active-submenu:hover {
  background-color: #0056b3;
  color: #fff;
}
</style>
