<template>
<LayoutUserManagment />
</template>

<script>
import LayoutUserManagment from '@/components/Layouts/LayoutUserManagment.vue';

export default {
      name: 'App',
      components: {
            LayoutUserManagment,
      },
};
</script>