<template>
   <div :class="['navbar-top', { collapsed: isCollapsed }]"
      class="d-flex flex-row justify-content-between align-items-center p-1">
      <div class="Option">
        <ul class="d-flex">
          <li @click="toggleSidebar">
            <span @click="resizeMenu"><i class="fa-solid fa-bars"></i></span>
          </li>
          <li><a href="#">Home</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </div>

      <div class="navbar-brand mt-3 m-3 d-flex align-items-center"></div>
      <div class="text d-flex align-items-center">
        <!-- Dark Mode Toggle -->
        <div class="nav-item me-4 mt-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="toggle" @change="toggleDarkMode"
              :checked="isDarkMode" />
            <div class="display">
              <label for="toggle">
                <div class="circle">
                  <!-- <svg class="sun" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path
                      d="M12 2.25a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM7.5 12a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM18.894 6.166a.75.75 0 0 0-1.06-1.06l-1.591 1.59a.75.75 0 1 0 1.06 1.061l1.591-1.59ZM21.75 12a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1 0-1.5H21a.75.75 0 0 1 .75.75ZM17.834 18.894a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 1 0-1.061 1.06l1.59 1.591ZM12 18a.75.75 0 0 1 .75.75V21a.75.75 0 0 1-1.5 0v-2.25A.75.75 0 0 1 12 18ZM7.758 17.303a.75.75 0 0 0-1.061-1.06l-1.591 1.59a.75.75 0 0 0 1.06 1.061l1.591-1.59ZM6 12a.75.75 0 0 1-.75.75H3a.75.75 0 0 1 0-1.5h2.25A.75.75 0 0 1 6 12ZM6.697 7.757a.75.75 0 0 0 1.06-1.06l-1.59-1.591a.75.75 0 0 0-1.061 1.06l1.59 1.591Z" />
                  </svg>
                  <svg class="moon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M9.528 1.718a.75.75 0 0 1 .162.819A8.97 8.97 0 0 0 9 6a9 9 0 0 0 9 9 8.97 8.97 0 0 0 3.463-.69.75.75 0 0 1 .981.98 10.503 10.503 0 0 1-9.694 6.46c-5.799 0-10.5-4.7-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 0 1 .818.162Z"
                      clip-rule="evenodd" />
                  </svg> -->
                </div>
              </label>
            </div>
          </div>
        </div>

        <!-- Notifications Icon -->
        <!-- <div class="nav-item me-4 mt-2"> -->
        <!-- <a href="#" class="nav-link text-white position-relative"> -->
        <!-- <i class="fas fa-bell fa-lg"></i> -->
        <!-- Font Awesome bell icon -->
        <!-- <span class="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-pill"> -->
        <!-- 3 -->
        <!-- </span> -->
        <!-- </a> -->
        <!-- </div> -->
        <!-- <button v-if="!user || !Datauser" type="button" @click="register">Register Now</button> -->

        <!-- User Profile Dropdown -->
        <div v-if="!user">
          <a class="nav-link text-primary" href="#/login">Login</a>
        </div>
        <div class="nav-item dropdown mt-1" v-if="user || Datauser">
          <!-- {{ user.userinfo_id }} -->
          <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <!-- <img @click="view_image" class="rounded-circle me-lg-1" :src="Datauser" alt="Profile"
              style="width: 56px; height: 52px; object-fit: cover" /> -->
            <span class="d-none d-lg-inline-flex">{{ user.name }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end border-0 rounded-0 rounded-bottom m-0">
            <a class="dropdown-item" @click="goToProfile">Profile</a>
            <a class="dropdown-item" href="#">Settings</a>
            <a class="dropdown-item" href="#/login" @click="logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import api from '@/services/apiService.js';

export default {
  name: "HeaderManagement",
  data() {
    return {
      isDarkMode: document.body.classList.contains("dark-mode"),
      user: null,
      Datauser: null
    };
  },
  methods: {
    resizeMenu(){
      this.$emit("handleResize");
    },
    async toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      document.body.classList.toggle("dark-mode", this.isDarkMode);
    },
    async fetchUserData() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.user = null;
          this.Datauser = null;
          return;
        }
        const response = await api.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.user = response.data;

        if (!this.user || !this.user.userinfo_id) {
          console.error('User info ID not found');
          return;
        }

        const userInfoResponse = await api.get(`/user_info/${this.user.userinfo_id}`);

        this.Datauser = userInfoResponse.data.data.photo;

      } catch (error) {
        console.error('Error fetching user data:', error);

      }
    },
    register() {
      this.$router.push("/register");
    },
    goToProfile() {
      if (this.user && this.user.userinfo_id) {
        this.$router.push(`/view-user/${this.user.userinfo_id}`);
      } else {
        console.error('User ID not available');
      }
    },


  },
  created() {
    this.fetchUserData();
  },

};
</script>
